export default [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    sorter: true,
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
  },
  {
    title: '仓库编号',
    dataIndex: 'warehouse_number',
    sorter: true,
  },
  {
    title: '仓库名称',
    dataIndex: 'warehouse_name',
  },
  // {
  //   title: '库位编号',
  //   dataIndex: 'location_number',
  //   sorter: true,
  // },
  {
    title: '数量',
    dataIndex: 'quantity',
    sorter: true,
  },
]